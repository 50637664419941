//import http from "../http-common";
import api from "./api";
/*
class CalendarService {
    getAll() {
        return api.get("/calendars");
    }

    get(id) {
        return api.get(`/calendar/${id}`);
    }

    create(data) {
        return api.post("/calendar", data);
    }

    update(id, data) {
        return api.put(`/calendar/${id}`, data);
    }

    delete(id) {
        return api.delete(`/calendar/${id}`);
    }
}
    */

const getAll = () => {
    return api.get("/calendars");
};

const get = id => {
    return api.get(`/calendar/${id}`);
};

const create = data => {
    return api.post("/calendar", data);
}

const update = (id, data) => {
    return api.put(`/calendar/${id}`, data);
}

const remove = id => {
    return api.delete(`/calendar/${id}`);
}

const CalendarService = {
    getAll,
    get,
    create,
    update,
    remove
};

export default CalendarService;