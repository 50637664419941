import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CalendarService from "../services/calendar.service";

const CalendarAssociationList = (props) => {
    const [currentCalendar, SetCurrentCurrentCalendar] = useState(null)
    const [calendars, SetCalendars] = useState([])
    const [currentIndex, SetCurrentIndex] = useState(-1)
    const [loadingCalendars, SetLoadingCalendars] = useState(false)

    const getCalendars = () => {
        SetLoadingCalendars(true)
        CalendarService.getAll()
            .then(response => {
                SetCalendars(response.data)
                console.log(response.data);
                SetLoadingCalendars(false)
            })
            .catch(e => {
                console.log(e);
                SetLoadingCalendars(false)
            });
    }

    const setActiveCalendar = (calendar, index) => {
        SetCurrentCurrentCalendar(calendar)
        SetCurrentIndex(index)
    }


    useEffect(() => {
        CalendarService.getAll()
            .then(response => {
                SetCalendars(response.data)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, [])

    return (
        <div class="container">
            <div class="row my-3">
                <h4>Calendars</h4>
            </div>
            <div class="row">
                <div class="col-md-4 mb-3">
                    <h5>List calendars</h5>
                    <ul class="list-group">
                        {calendars &&
                            calendars.map((calendar, index) => (
                                <li
                                    class={
                                        "list-group-item list-group-item-light " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => setActiveCalendar(calendar, index)}
                                    key={calendar.id}
                                >
                                    {calendar.name}
                                </li>
                            ))}
                    </ul>
                </div>
                <div class="col-md">
                    {currentCalendar ? (
                        <div>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                {currentCalendar.name}
                            </div>
                            <div class="mb-3">
                                <Link

                                    to={{ pathname: `/add-calendar-association/${currentCalendar.id}` }}
                                    type="button" class="btn btn-primary mx-2"
                                >
                                    Edit
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <p>Please select a calendar...</p>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}
export default CalendarAssociationList;