

import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import TokenService from "../services/token.service";
import Navbar from "./navbar";

const PrivateRoute = () => {
    const user = TokenService.getUser()
    if (!user) return <Navigate to="/login" />;
    return <div>
        <Navbar />
        <Outlet />
    </div>;
};

export default PrivateRoute;
