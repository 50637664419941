import api from "./api";
//import http from "../http-common";
/*
class DeviceService {
    getAll(calendarId, withoutCalendarId, pageSize, page) {
        return http.get("thingsboard/devices", {
            params: {
                "calendarId": calendarId,
                "withoutCalendarId": withoutCalendarId,
                "pageSize": pageSize,
                "page": page
            }
        });
    }

    updateAttribute(id, data) {
        return http.post("/thingsboard/device/" + id + "/attributes", data);
    }

    deleteAttribute(id, attributes) {
        return http.delete("/thingsboard/device/" + id + "/attributes", {
            params: {
                "keys": attributes
            }
        });
    }

}
    */

const getAll = (calendarId, withoutCalendarId, pageSize, page) => {
    return api.get("thingsboard/devices", {
        params: {
            "calendarId": calendarId,
            "withoutCalendarId": withoutCalendarId,
            "pageSize": pageSize,
            "page": page
        }
    });
}

const updateAttribute = (id, data) => {
    return api.post("/thingsboard/device/" + id + "/attributes", data);
}

const deleteAttribute = (id, attributes) => {
    return api.delete("/thingsboard/device/" + id + "/attributes", {
        params: {
            "keys": attributes
        }
    });
}

const DeviceService = {
    getAll,
    updateAttribute,
    deleteAttribute
};

export default DeviceService;