import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ControlProgramService from "../services/control-program.service";
import { Button } from "react-bootstrap";

const ControlProgramList = (props) => {
    const [currentControlProgram, SetCurrentControlProgram] = useState(null)
    const [controlPrograms, SetControlPrograms] = useState([])
    const [currentIndex, SetCurrentIndex] = useState(-1)

    const setActiveControlProgram = (controlProgram, index) => {
        SetCurrentControlProgram(controlProgram)
        SetCurrentIndex(index)
    }

    const deleteContronlProgram = (controlProgram) => {
        ControlProgramService.remove(controlProgram.id)
            .then(response => {
                SetCurrentControlProgram(null)
                SetControlPrograms((current) =>
                    current.filter((item) => item.id !== controlProgram.id))
                SetCurrentIndex(-1)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }


    useEffect(() => {
        ControlProgramService.getAll()
            .then(response => {
                SetControlPrograms(response.data)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, [])

    return (
        <div className="container">
            <div className="row my-3">
                <h4>Control programs</h4>
            </div>
            <div className="row">
                <div className="col-md-4 mb-3">
                    <h5>List control programs</h5>
                    <ul className="list-group">
                        {controlPrograms &&
                            controlPrograms.map((controlProgram, index) => (
                                <li
                                    className={
                                        "list-group-item list-group-item-light " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => setActiveControlProgram(controlProgram, index)}
                                    key={controlProgram.id}
                                >
                                    {controlProgram.name}
                                </li>
                            ))}
                    </ul>
                    <Link
                        to="/add"
                        state={{ currentControlProgram: null }}
                        type="button"
                        className="btn btn-primary my-3">
                        Add
                    </Link>
                </div>
                <div className="col-md">
                    {currentControlProgram ? (
                        <div>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                {currentControlProgram.name}
                            </div>
                            <div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Type event</th>
                                            <th>Fixed time</th>
                                            <th>Astro type event</th>
                                            <th>Offset (in min)</th>
                                            <th>Dimming level (in %)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentControlProgram.timeControls &&
                                            currentControlProgram.timeControls.map((timeControl, index) => (
                                                <tr key={`${index}`}>
                                                    <td>{timeControl.timeEvtType}</td>
                                                    <td>{timeControl.fixedTime}</td>
                                                    <td>{timeControl.astroTypeEvt}</td>
                                                    <td>{timeControl.offset}</td>
                                                    <td>{timeControl.dimmingLevel}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="mb-3">
                                <Link
                                    to="/add"
                                    state={{ currentControlProgram }}
                                    type="button" className="btn btn-primary mx-2"
                                >
                                    Edit
                                </Link>
                                <Button
                                    type="button"
                                    className="btn btn-primary mx-2"
                                    onClick={() => deleteContronlProgram(currentControlProgram)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <p>Please select a control program...</p>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}
export default ControlProgramList;
