import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import CalendarService from "../services/calendar.service"
import DeviceService from "../services/device.service"

const AddCalendarAssociation = (props) => {
    const { id } = useParams()
    const [calendar, setCalendar] = useState(null)
    const [devicesWithCalendar, setDevicesWithCalendar] = useState([])
    const [isLoadingDevices, setIsLoadingDevices] = useState(true)
    const [devicesWithoutCalendar, setDevicesWithoutCalendar] = useState([])
    const [isLoadingOtherDevices, setIsLoadingOtherDevices] = useState(true)
    const [isLoadingCalendar, setIsLoadingCalendar] = useState(false)
    const [errorCalendarId, setErrorCalendarId] = useState(false)


    const LoadCalendar = () => {
        setIsLoadingCalendar(true)
        CalendarService.get(id)
            .then(response => {
                setCalendar(response.data)
                setIsLoadingCalendar(false)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
                setErrorCalendarId(true)
            });
    }
    const LoadDevices = () => {
        setIsLoadingDevices(true)
        DeviceService.getAll(id, null, 50, 0)
            .then(response => {
                setDevicesWithCalendar(response.data)
                setIsLoadingDevices(false)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });

        setIsLoadingOtherDevices(true)
        DeviceService.getAll(null, id, 50, 0)
            .then(response => {
                setDevicesWithoutCalendar(response.data)
                setIsLoadingOtherDevices(false)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }

    const deleteCalendarId = (deviceId) => {
        DeviceService.deleteAttribute(deviceId, "calendarId")
            .then(response => {
                console.log(response.data);
                LoadCalendar()
                LoadDevices()
            })
            .catch(e => {
                console.log(e);
            });
    }

    const addCalendarId = (deviceId) => {
        DeviceService.updateAttribute(deviceId, {
            "calendarId": id
        })
            .then(response => {
                console.log(response.data);
                LoadCalendar()
                LoadDevices()
            })
            .catch(e => {
                console.log(e);
            });
    }

    useEffect(() => {
        LoadCalendar()
        LoadDevices()
    }, [])

    return (
        <div className="container">
            <div className="row my-3">
                <h4>Calendar association</h4>
            </div>
            {isLoadingCalendar && isLoadingCalendar === true ?
                (<div>Loading...</div>) :
                (errorCalendarId && errorCalendarId === true ?
                    (<div>Calendar id does not exist</div>) :
                    calendar ?
                        (<div>
                            <p>Calendar name: {calendar.name}</p>
                        </div>) : null)
            }
            <div className="row my-3">
                <h4>Devices associated with calendar</h4>
            </div>
            {isLoadingDevices && isLoadingDevices === true ?
                (<div>Loading...</div>) :
                <div>
                    <table className="table">
                        <thead>
                            <tr className="row">
                                <th className="col-6">Device name</th>
                                <th className="col-6">Control</th>
                            </tr>
                        </thead>
                        <tbody>
                            {devicesWithCalendar && devicesWithCalendar.data &&
                                devicesWithCalendar.data.map((device, index) => (
                                    <tr className="row" key={index}>
                                        <td className="col-6">{device.name}</td>
                                        <td className="col-6">
                                            <button type="button" className="btn btn-link" onClick={() => deleteCalendarId(device.id)}>
                                                Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div >
            }
            <div className="row my-3">
                <h4>Other devices</h4>
            </div>
            {
                isLoadingOtherDevices && isLoadingOtherDevices === true ?
                    (<div>Loading...</div>) :
                    <div>
                        <table className="table">
                            <thead>
                                <tr className="row">
                                    <th className="col-6">Device name</th>
                                    <th className="col-6">Control</th>
                                </tr>
                            </thead>
                            <tbody>
                                {devicesWithoutCalendar && devicesWithoutCalendar.data &&
                                    devicesWithoutCalendar.data.map((device, index) => (
                                        <tr className="row" key={index}>
                                            <td className="col-6">{device.name}</td>
                                            <td className="col-6">
                                                <button type="button" className="btn btn-link" onClick={() => addCalendarId(device.id)}>
                                                    Add
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
            }
        </div >

    )
}
export default AddCalendarAssociation;