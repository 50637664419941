import React, { useState, useEffect } from "react";
import ModalTimeControl from "./modal-time-control";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ControlProgramService from "../services/control-program.service";

const AddControlProgram = (props) => {
    const location = useLocation()
    const [formError, setformError] = useState(false)
    const [modalTimeControlShow, setModalTimeControlShow] = useState(false)
    const [currentIndexTimeControl, setCurrentIndexTimeControl] = useState(-1)
    const [name, setName] = useState("")
    const [timeControls, setTimeControl] = useState([])
    const [version, setVersion] = useState()
    const [id, setId] = useState()
    const [controlProgramId, setControlProgramId] = useState()
    const [customerId, setCustomerid] = useState()

    const handleChangeName = (event) => {
        setName(event.target.value)
    }

    const addTimeControl = (timeControl, index) => {
        if (index >= 0) {
            updateTimeControl(timeControl, index)
        }
        else {
            timeControls.push(timeControl)
        }
    }

    const updateTimeControl = (timeControl, index) => {
        const newTimeControls = timeControls.map((item, i) => {
            if (i === index)
                return timeControl
            else
                return item
        })
        setTimeControl(newTimeControls)
    }

    const deleteTimeControl = (index) => {
        setTimeControl(timeControls.filter((_, i) => i !== index))
    }

    const upTimeControl = (index) => {
        setTimeControl(
            [...timeControls.slice(0, index - 1),
            ...timeControls.slice(index - 1, index + 1).reverse(),
            ...timeControls.slice(index + 1)
            ])
    }
    const downTimeControl = (index) => {
        setTimeControl(
            [...timeControls.slice(0, index),
            ...timeControls.slice(index, index + 2).reverse(),
            ...timeControls.slice(index + 2)
            ])
    }

    const requestUpdateTimeControl = (index) => {
        setCurrentIndexTimeControl(index)
        setModalTimeControlShow(true)
    }

    const requestAddTimeControl = () => {
        setCurrentIndexTimeControl(-1)
        setModalTimeControlShow(true)
    }

    let navigate = useNavigate();

    const handleSubmit = () => {
        //build obj
        const controlProgram = {
            id,
            controlProgramId,
            version,
            customerId,
            name,
            timeControls
        }
        console.log(controlProgram)
        if (id == null) {
            ControlProgramService.create(controlProgram)
                .then(response => {
                    if (response.status === 200) {
                        setformError(false)
                        navigate('/');
                    }
                    else {
                        setformError(true)
                    }
                    console.log(response.data)
                })
                .catch(e => {
                    setformError(true)
                    console.log(e);
                });
        }
        else {
            ControlProgramService.update(id, controlProgram)
                .then(response => {
                    if (response.status === 200) {
                        setformError(false)
                        navigate('/');
                    }
                    else {
                        setformError(true)
                    }
                    console.log(response.data)
                })
                .catch(e => {
                    setformError(true)
                    console.log(e);
                });
        }
    }

    useEffect(() => {
        if (location.state !== null &&
            location.state.currentControlProgram !== undefined) {
            const { currentControlProgram } = location.state;
            if (currentControlProgram !== null) {
                setId(currentControlProgram.id)
                setControlProgramId(currentControlProgram.controlProgramId)
                setVersion(currentControlProgram.version)
                setName(currentControlProgram.name)
                setTimeControl(currentControlProgram.timeControls)
                setCustomerid(currentControlProgram.customerId)

            }
        }
    }, [])

    return (
        <div class="container">
            <div class="row my-2">
                {id ?
                    (<h2>Modify control program</h2>) :
                    (<h2>Create control program</h2>)
                }

            </div>
            <form>
                <div class="row mb-3">
                    <div class="form-group">
                        <label for="name">Name:</label>
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            required
                            value={name}
                            onChange={handleChangeName}
                            name="name"
                        />
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-12">
                        <h5>Time control list</h5>
                    </div>
                    <ul class="col-12 list-group">
                        <div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Type event</th>
                                        <th>Fixed time</th>
                                        <th>Astro type event</th>
                                        <th>Offset (in min)</th>
                                        <th>Dimming level (in %)</th>
                                        <th>Control</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {timeControls &&
                                        timeControls.map((timeControl, index) => (
                                            <tr key={index}>
                                                <td>{timeControl.timeEvtType}</td>
                                                <td>{timeControl.fixedTime}</td>
                                                <td>{timeControl.astroTypeEvt}</td>
                                                <td>{timeControl.offset}</td>
                                                <td>{timeControl.dimmingLevel}</td>
                                                <td>
                                                    <button type="button" class="btn btn-link" onClick={() => deleteTimeControl(index)}>
                                                        Delete
                                                    </button>
                                                    <button type="button" class="btn btn-link" onClick={() => upTimeControl(index)}>
                                                        Up
                                                    </button>
                                                    <button type="button" class="btn btn-link" onClick={() => downTimeControl(index)}>
                                                        Down
                                                    </button>
                                                    <button type="button" class="btn btn-link" onClick={() => requestUpdateTimeControl(index)}>
                                                        Modify
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </ul>
                    <div class="col-12">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-toggle="modal"
                            onClick={() => requestAddTimeControl()}>
                            Add
                        </button>
                        <ModalTimeControl
                            show={modalTimeControlShow}
                            onClose={() => setModalTimeControlShow(false)}
                            timeControls={timeControls}
                            index={currentIndexTimeControl}
                            add={(timeControl, i) => addTimeControl(timeControl, i)} />
                    </div>
                </div>
                <div class="row my-3">
                    <div class="col-12 text-end">
                        <Link
                            to="/"
                            type="button"
                            class="btn btn-primary mx-2">
                            Cancel
                        </Link>
                        <button type="button" class="btn btn-primary  mx-2" onClick={handleSubmit}>
                            Save
                        </button>

                    </div>
                </div >
                {
                    formError && formError === true ?
                        (<div class="alert alert-danger" role="alert">
                            Cannot save Control program!
                        </div>) : (null)
                }

            </form >
        </div >
    )
}
export default AddControlProgram;
