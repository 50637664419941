import api from "./api";
import TokenService from "./token.service";

const register = (username, email, password) => {
    return api.post("/auth/signup", {
        username,
        email,
        password
    });
};

const login = (user, psw) => {
    return api
        .post("/login", {
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        }, {
            auth: {
                username: user,
                password: psw
            }
        })
        .then((response) => {
            if (response.data.token) {
                TokenService.setUser(response.data);
            }

            return response.data;
        });
};

const logout = () => {
    TokenService.removeUser();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
    register,
    login,
    logout,
    getCurrentUser,
};

export default AuthService;