
import api from "./api";

const getAll = () => {
    return api.get("/controlprograms");
};

const get = id => {
    return api.get(`/controlprogram/${id}`);
};

const getByControlProgramId = id => {
    return api.get(`/controlprogram/controlProgramId/${id}`);
}

const create = data => {
    return api.post("/controlprogram", data);
}

const update = (id, data) => {
    return api.put(`/controlprogram/${id}`, data);
}

const remove = id => {
    return api.delete(`/controlprogram/${id}`);
}

const ControlProgramService = {
    getAll,
    get,
    getByControlProgramId,
    create,
    update,
    remove
};

export default ControlProgramService;