import React, { Component, useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login";
import PrivateRoute from "./components/privateRoute";
import ControlProgramList from "./components/control-program-list.component";
import AddControlProgram from "./components/add-control-program.component";
import CalendarList from "./components/calendar-list";
import AddCalendar from "./components/add-calendar";
import CalendarAssociationList from "./components/calendar-association-list";
import AddCalendarAssociaton from "./components/add-calendar-association"
import CommissioningCalendar from "./components/commissioning-calendar";

/*class App extends Component {
    render() {*/
function App() {

    return (
        <div>
            <div className="container mt-3">

                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route element={<PrivateRoute />}>
                        <Route path="/" element={<ControlProgramList />} />
                        <Route path="/controlprograms" element={<ControlProgramList />} />
                        <Route path="/calendar" element={<CalendarList />} />
                        <Route path="/calendar-association" element={<CalendarAssociationList />} />
                        <Route path="/add-calendar-association/:id" element={<AddCalendarAssociaton />} />
                        <Route path="/add" element={<AddControlProgram />} />
                        <Route path="/calendars" element={<CalendarList />} />
                        <Route path="/add-calendar" element={<AddCalendar />} />
                        <Route path="/commissioning-calendar" element={<CommissioningCalendar />} />
                    </Route>
                </Routes>
            </div>

        </div>

    );
}
//}

export default App;
