import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ModalTimeControl = (props) => {
    const { show, onClose, add, timeControls, index, update } = props;
    const [timeControl, setTimeControl] = useState({
        timeEvtType: 'Fixed time',
        fixedTime: '18:00',
        astroTypeEvt: 'Sunset',
        offset: 0,
        dimmingLevel: 100
    });

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var isError = false

        if (name === 'dimmingLevel') {
            let regex = /\d/;
            if (value < 0 || value > 100 || !regex.test(value))
                isError = true
        }
        if (isError === false)
            setTimeControl(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        //const currentTimeControl = timeControl

        if (timeControl.timeEvtType === "Fixed time") {
            const { astroTypeEvt, offset, ...restObj } = timeControl;
            add(restObj, index)
            //delete currentTimeControl.astroTypeEvt
            //delete currentTimeControl.offset
        }
        else {
            const { fixedTime, ...restObj } = timeControl;
            add(restObj, index)
            //delete currentTimeControl.fixedTime
        }
        //add(currentTimeControl)
        onClose()
    }

    useEffect(() => {

        if (index >= 0) {
            setTimeControl(timeControls[index])
        }
    }, [index])



    return (
        <div>
            <Modal show={show} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Time Control</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="form-group">
                            <label for="inputTimeEvtType">Time event type:</label>
                            <select id="inputTimeEvtType" class="form-control" name="timeEvtType"
                                value={timeControl.timeEvtType} onChange={handleChange}>
                                <option>Fixed time</option>
                                <option>Astro time</option>
                            </select>
                        </div>

                        {timeControl.timeEvtType === 'Fixed time' ?
                            <div class="form-group">
                                <label for="inputFixedTime">Fixed time</label>
                                <input type="time" class="form-control" id="inputFixedTime" placeholder="Fixed time"
                                    name="fixedTime" value={timeControl.fixedTime} onChange={handleChange} />
                            </div>
                            :
                            <div>
                                <div class="form-group">
                                    <label for="inputAstroTypeEvt">Astro event type:</label>
                                    <select id="inputAstroTypeEvt" class="form-control"
                                        name="astroTypeEvt" value={timeControl.astroTypeEvt} onChange={handleChange}>
                                        <option>Sunset</option>
                                        <option>Sunrise</option>
                                    </select>
                                </div>

                                <div class="form-group">
                                    <label for="inputOffset">Offset (in minutes)</label>
                                    <input type="number" min="-86400" max="86400" step="1"
                                        class="form-control" id="inputOffset" placeholder="Offset"
                                        name="offset" value={timeControl.offset} onChange={handleChange} />
                                </div>
                            </div>
                        }

                        <div class="form-group">
                            <label for="inputDimming">Dimming level (in %)</label>
                            <input type="number" min="0" max="100" step="1"
                                class="form-control" id="inputDimming" placeholder="Dimming level"
                                name="dimmingLevel" value={timeControl.dimmingLevel} onChange={handleChange} />
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onClose}>Cancel</Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default ModalTimeControl;