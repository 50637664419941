import React, { useState } from 'react';
import AuthService from '../services/auth.service';
import { useNavigate } from 'react-router-dom';


export default function Login() {
    const [input, setInput] = useState({
        username: "",
        password: "",
    });
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState("")

    const handleInput = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (input.username !== "" && input.password !== "") {
            AuthService.login(input.username, input.password)
                .then(response => {
                    setErrorMsg("")
                    navigate('/');

                })
                .catch(e => {
                    setErrorMsg(e.message)
                    console.log(e.message);
                });
        }
        else {
            setErrorMsg("Please enter valid username and password")
        }
    }


    return (
        <div className="container">
            <div className="row my-3">
                <h4>Login</h4>
            </div>
            <form onSubmit={handleSubmit} className="row my-3">
                <div data-mdb-input-init className="form-outline mb-4">
                    <input
                        type="email"
                        id="user-email"
                        name="username"
                        className="form-control"
                        placeholder="Email address"
                        onChange={handleInput} />
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                    <input
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={handleInput} />
                </div>
                <button
                    type="submit"
                    data-mdb-button-init data-mdb-ripple-init
                    className="btn btn-primary btn-block mb-4">
                    Sign in
                </button>
                {
                    errorMsg && errorMsg !== "" ?
                        (<div className="alert alert-danger" role="alert">
                            {errorMsg}
                        </div>) : (null)
                }
            </form>
        </div >
    )
}
