import axios from "axios";
import TokenService from "./token.service";
import { useNavigate } from "react-router-dom";

export const Hostname = process.env.REACT_APP_HOSTNAME;
export const Port = process.env.REACT_APP_PORT;

const api = axios.create({
    baseURL: Hostname + ":" + Port,
    headers: {
        "Content-Type": "application/json"
    }
});

api.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalToken();
        if (token) {
            config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
            //config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== "/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                TokenService.removeUser()
                const navigate = useNavigate()
                navigate("/login")
                /*
                                try {
                                    const rs = await api.post("/auth/refreshtoken", {
                                        refreshToken: TokenService.getLocalRefreshToken(),
                                    });
                
                                    const { accessToken } = rs.data;
                                    TokenService.updateLocalAccessToken(accessToken);
                
                                    return api(originalConfig);
                                } catch (_error) {
                                    return Promise.reject(_error);
                                }
                */
            }
        }

        return Promise.reject(err);
    }
);

export default api;