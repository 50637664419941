import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ModalCalendarRule = (props) => {
    const { show, onClose, controlPrograms, add, index, rules } = props;

    const timeConditionTypeValue = {
        ccDate: 1,
        ccDay: 2
    }
    const dayOfWeek = {
        ccMonday: "Monday",
        ccTuesday: "Thuesday",
        ccWednesday: "Wednesday",
        ccThursday: "Thursday",
        ccFriday: "Friday",
        ccSaturday: "Saturday",
        ccSunday: "Sunday"
    }

    const [ctrProgId, setCtrProgId] = useState(null)
    const [timeConditiontype, setTimeConditiontype] = useState(timeConditionTypeValue.ccDate)
    const [start, setStart] = useState()
    const [end, setEnd] = useState()
    const [isStart, setIsStart] = useState(true)
    const [isEnd, setIsEnd] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();

        const rule = { ctrProgId, timeConditiontype, start, end }
        add(rule, index)
        onClose()
    }

    const setCurrentDate = () => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setStart(formattedDate)
    }

    const updateIsEnd = (e) => {
        let value = e.target.checked
        if (value === false)
            setEnd(null)
        setIsEnd(value)
    }

    const updateTimeConditionType = (e) => {
        let value = e.target.value
        if (value == timeConditionTypeValue.ccDate) {
            setCurrentDate()
            setEnd(null)
        }
        else {
            const test = dayOfWeek.ccMonday
            setStart(test)
            setEnd(dayOfWeek.ccSunday)
        }
        setTimeConditiontype(value)
    }

    useEffect(() => {
        setCurrentDate()
    }, [])

    useEffect(() => {
        if (ctrProgId === null && controlPrograms && controlPrograms.length > 0)
            setCtrProgId(controlPrograms[0].controlProgramId)
    }, [controlPrograms])

    useEffect(() => {

        if (index >= 0) {
            setCtrProgId(rules[index].ctrProgId)
            setTimeConditiontype(rules[index].timeConditiontype)
            setStart(rules[index].start)
            setEnd(rules[index].end)
        }
    }, [index])

    return (
        <div>
            <Modal show={show} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Rule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div class="form-group">
                            <label for="controlProgram">Control program:</label>
                            <select
                                id="controlProgram"
                                class="form-control"
                                name="controlProgram"
                                value={ctrProgId}
                                onChange={(e) => setCtrProgId(e.target.value)}
                            >
                                {controlPrograms && controlPrograms.map((controlProgram, index) => (
                                    <option key={index} value={controlProgram.controlProgramId}>{controlProgram.name}</option>
                                ))}
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="timeConditionType">Time condition type:</label>
                            <select
                                id="timeConditionType"
                                class="form-control"
                                name="timeConditionType"
                                value={timeConditiontype}
                                onChange={(e) => updateTimeConditionType(e)}
                            >
                                <option value={timeConditionTypeValue.ccDate} >date</option>
                                <option value={timeConditionTypeValue.ccDay} >days of the week</option>
                            </select>
                        </div>
                        {timeConditiontype && timeConditiontype == timeConditionTypeValue.ccDate ?
                            (
                                <div>
                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            checked={isStart}
                                            id="isStart"
                                            onChange={(e) => setIsStart(e.target.checked)}
                                        />
                                        <label class="form-check-label" for="defineStartDate">
                                            Define start date
                                        </label>
                                    </div>
                                    {isStart && isStart === true ?
                                        (<div class="form-group">
                                            <label for="startDate">Start date</label>
                                            <input
                                                type="date"
                                                class="form-control"
                                                id="inputFixedTime"
                                                placeholder="Start date"
                                                name="startDate"
                                                value={start}
                                                onChange={(e) => setStart(e.target.value)} />
                                        </div>) :
                                        null
                                    }

                                    <div class="form-check">
                                        <input
                                            class="form-check-input"
                                            type="checkbox"
                                            checked={isEnd}
                                            id="isEnd"
                                            onChange={(e) => updateIsEnd(e)}
                                        />
                                        <label class="form-check-label" for="defineStartDate">
                                            Define end date
                                        </label>
                                    </div>
                                    {isEnd && isEnd === true ?
                                        (<div class="form-group">
                                            <label for="endDate">End date</label>
                                            <input type="date" class="form-control" id="endDate" placeholder="End date"
                                                name="endDate" value={end} onChange={(e) => setEnd(e.target.value)} />
                                        </div>) :
                                        null
                                    }

                                </div>
                            )
                            :
                            (
                                <div>
                                    <div class="form-group">
                                        <label for="startDay">Start day:</label>
                                        <select
                                            id="timeConditionType"
                                            class="form-control"
                                            name="timeConditionType"
                                            value={start}
                                            onChange={(e) => setStart(e.target.value)}
                                        >
                                            <option value={dayOfWeek.ccMonday} >{dayOfWeek.ccMonday}</option>
                                            <option value={dayOfWeek.ccTuesday} >{dayOfWeek.ccTuesday}</option>
                                            <option value={dayOfWeek.ccWednesday} >{dayOfWeek.ccWednesday}</option>
                                            <option value={dayOfWeek.ccThursday} >{dayOfWeek.ccThursday}</option>
                                            <option value={dayOfWeek.ccFriday} >{dayOfWeek.ccFriday}</option>
                                            <option value={dayOfWeek.ccSaturday} >{dayOfWeek.ccSaturday}</option>
                                            <option value={dayOfWeek.ccSunday} >{dayOfWeek.ccSunday}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="endDay">End day:</label>
                                        <select
                                            id="timeConditionType"
                                            class="form-control"
                                            name="timeConditionType"
                                            value={end}
                                            onChange={(e) => setEnd(e.target.value)}
                                        >
                                            <option value={dayOfWeek.ccMonday} >{dayOfWeek.ccMonday}</option>
                                            <option value={dayOfWeek.ccTuesday} >{dayOfWeek.ccTuesday}</option>
                                            <option value={dayOfWeek.ccWednesday} >{dayOfWeek.ccWednesday}</option>
                                            <option value={dayOfWeek.ccThursday} >{dayOfWeek.ccThursday}</option>
                                            <option value={dayOfWeek.ccFriday} >{dayOfWeek.ccFriday}</option>
                                            <option value={dayOfWeek.ccSaturday} >{dayOfWeek.ccSaturday}</option>
                                            <option value={dayOfWeek.ccSunday} >{dayOfWeek.ccSunday}</option>
                                        </select>
                                    </div>
                                </div>
                            )
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={onClose}>Cancel</Button>
                    <Button variant="primary" type="submit" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default ModalCalendarRule;