import React from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../services/auth.service';

const Navbar = () => {
    const logOut = () => {
        AuthService.logout();
    };

    return (
        <div className='navBar'>
            <nav className="px-3 navbar navbar-expand navbar-dark bg-dark">
                <Link to={"/"} className="navbar-brand">
                    Home
                </Link>
                <div className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <Link to={"/controlprograms"} className="nav-link">
                            Control programs
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/calendar"} className="nav-link">
                            Calendar
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/calendar-association"} className="nav-link">
                            Calendar association
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to={"/commissioning-calendar"} className="nav-link">
                            Commissioning calendar
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href="/login" className="nav-link" onClick={logOut}>
                            LogOut
                        </a>
                    </li>
                </div>
            </nav>
        </div>
    )

}

export default Navbar;