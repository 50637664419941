//import http from "../http-common";
import api from "./api";

/*
class CommissioningService {

    update(id) {
        return api.put(`/commissioning/${id}`);
    }


}
    */

const update = (id) => {
    return api.put(`/commissioning/${id}`);
}

const CommissioningService = {
    update
};

export default CommissioningService;