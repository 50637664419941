import React, { useState, useEffect } from "react"
import ControlProgramService from "../services/control-program.service"
import ModalCalendarRule from "./modal-calendar-rule"
import { Link, useNavigate, useLocation } from "react-router-dom"
import CalendarService from "../services/calendar.service"

const AddCalendar = (props) => {
    const location = useLocation()
    const [modalCalendarRulelShow, setModalCalendarRuleShow] = useState(false)
    const [formError, setformError] = useState(false)

    const [isLoading, setIsLoading] = useState()
    const [id, setId] = useState()
    const [name, setName] = useState("")
    const [version, setVersion] = useState()
    const [customerId, setCustomerId] = useState()
    const [calendarId, setCalendarId] = useState()
    const [defaultCtrProgId, setDefaultCtrProgId] = useState()
    const [rules, setRules] = useState([])
    const [lamp, setLamp] = useState(1)

    const [controlPrograms, setControlPrograms] = useState([])
    const [currentIndexRule, setCurrentIndexRule] = useState(-1)

    const LoadControlPrograms = () => {
        setIsLoading(true)
        ControlProgramService.getAll()
            .then(response => {
                setControlPrograms(response.data)
                setIsLoading(false)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }


    const deleteRule = (index) => {
        setRules(rules.filter((_, i) => i !== index))
    }

    const upRule = (index) => {
        setRules(
            [...rules.slice(0, index - 1),
            ...rules.slice(index - 1, index + 1).reverse(),
            ...rules.slice(index + 1)
            ])
    }
    const downRule = (index) => {
        setRules(
            [...rules.slice(0, index),
            ...rules.slice(index, index + 2).reverse(),
            ...rules.slice(index + 2)
            ])
    }

    const requestUpdateRule = (index) => {
        setCurrentIndexRule(index)
        setModalCalendarRuleShow(true)
    }

    const updateRule = (rule, index) => {
        const newRules = rules.map((item, i) => {
            if (i === index)
                return rule
            else
                return item
        })
        setRules(newRules)
    }

    const addRule = (rule, index) => {
        if (index >= 0) {
            updateRule(rule, index)
        }
        else {
            setRules([...rules, rule])
        }
    }

    const requestAddRule = () => {
        setCurrentIndexRule(-1)
        setModalCalendarRuleShow(true)
    }

    const getControlProgramName = (controlProgramId) => {
        const selectControlProgram = controlPrograms.filter(item => item.controlProgramId == controlProgramId)
        if (selectControlProgram !== null && selectControlProgram.length > 0) {
            return selectControlProgram[0].name
        }
        else
            return null
    }

    useEffect(() => {
        LoadControlPrograms()

        if (location.state !== null &&
            location.state.currentCalendar !== undefined) {
            const { currentCalendar } = location.state;
            if (currentCalendar !== null) {
                setId(currentCalendar.id)
                setDefaultCtrProgId(currentCalendar.defaultCtrProgId)
                setVersion(currentCalendar.version)
                setCustomerId(currentCalendar.customerId)
                setName(currentCalendar.name)
                setRules(currentCalendar.rules)
                setLamp(currentCalendar.lamp)
                setCalendarId(currentCalendar.calendarId)
            }
        }
    }, [])

    useEffect(() => {
        if (defaultCtrProgId == null && controlPrograms && controlPrograms.length > 0) {
            setDefaultCtrProgId(controlPrograms[0].controlProgramId)
        }
    }, [controlPrograms])

    let navigate = useNavigate();

    const handleSubmit = () => {
        //build obj
        const calendar = {
            id,
            name,
            version,
            customerId,
            calendarId,
            defaultCtrProgId,
            rules,
            lamp
        }
        console.log(calendar)
        if (id == null) {
            CalendarService.create(JSON.stringify(calendar))
                .then(response => {
                    if (response.status === 200) {
                        setformError(false)
                        navigate('/calendars');
                    }
                    else {
                        setformError(true)
                    }
                    console.log(response.data)
                })
                .catch(e => {
                    setformError(true)
                    console.log(e);
                });
        }
        else {
            CalendarService.update(id, calendar)
                .then(response => {
                    if (response.status === 200) {
                        setformError(false)
                        navigate('/calendars');
                    }
                    else {
                        setformError(true)
                    }
                    console.log(response.data)
                })
                .catch(e => {
                    setformError(true)
                    console.log(e);
                });
        }


    }



    return (
        <div class="container">
            <div class="row my-3">
                <h4>Create calendar</h4>
            </div>
            {isLoading && isLoading === true ?
                (<div>Loading...</div>) :
                (<form>
                    <div class="row mb-3">
                        <div class="form-group">
                            <label for="name">Name:</label>
                            <input
                                type="text"
                                class="form-control"
                                id="name"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                name="name"
                            />

                        </div>
                    </div>
                    <div class="form-group">
                        <label for="defaultCtrProgId">Default control program:</label>
                        <select
                            id="defaultCtrProgId"
                            class="form-control"
                            name="defaultCtrProgId"
                            value={defaultCtrProgId}
                            onChange={(e) => setDefaultCtrProgId(e.target.value)}
                        >
                            {controlPrograms && controlPrograms.map((controlProgram, index) => (
                                <option key={index} value={controlProgram.controlProgramId}>{controlProgram.name}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <div class="mt-3">
                            <div><h5>Rules</h5></div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Control program</th>
                                        <th>Time control type</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Control</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rules &&
                                        rules.map((rule, index) => (
                                            <tr key={index}>
                                                <td>{getControlProgramName(rule.ctrProgId)}</td>
                                                <td>{rule.timeConditiontype === 1 ? ("date") : ("days of the week")}</td>
                                                <td>{rule.start}</td>
                                                <td>{rule.end}</td>
                                                <td>
                                                    <button type="button" class="btn btn-link" onClick={() => deleteRule(index)}>
                                                        Delete
                                                    </button>
                                                    <button type="button" class="btn btn-link" onClick={() => upRule(index)}>
                                                        Up
                                                    </button>
                                                    <button type="button" class="btn btn-link" onClick={() => downRule(index)}>
                                                        Down
                                                    </button>
                                                    <button type="button" class="btn btn-link" onClick={() => requestUpdateRule(index)}>
                                                        Modify
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-toggle="modal"
                            onClick={() => requestAddRule()}>
                            Add
                        </button>
                        <ModalCalendarRule
                            show={modalCalendarRulelShow}
                            rules={rules}
                            controlPrograms={controlPrograms}
                            index={currentIndexRule}
                            add={(rule, i) => addRule(rule, i)}
                            onClose={() => setModalCalendarRuleShow(false)} />
                    </div>
                    <div class="row my-3">
                        <div class="col-12 text-end">
                            <Link
                                to="/calendars"
                                type="button"
                                class="btn btn-primary mx-2">
                                Cancel
                            </Link>
                            <button type="button" class="btn btn-primary  mx-2" onClick={handleSubmit}>
                                Save
                            </button>

                        </div>
                    </div >
                    {
                        formError && formError === true ?
                            (<div class="alert alert-danger" role="alert">
                                Cannot save Calendar!
                            </div>) : (null)
                    }
                </form >)
            }
        </div >

    )
}
export default AddCalendar;