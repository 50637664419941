import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CalendarService from "../services/calendar.service";
import CommissioningService from "../services/commissioning.service";

const CommissioningCalendar = (props) => {
    const [currentCalendar, SetCurrentCurrentCalendar] = useState(null)
    const [calendars, SetCalendars] = useState([])
    const [currentIndex, SetCurrentIndex] = useState(-1)
    const [loadingCalendars, SetLoadingCalendars] = useState(false)
    const [disableCommissioning, SetDisableCommissioning] = useState(false)
    const [formError, SetFormError] = useState(false)
    const [formSuccessfull, SetFormSuccessfull] = useState(false)

    const getCalendars = () => {
        SetLoadingCalendars(true)
        CalendarService.getAll()
            .then(response => {
                SetCalendars(response.data)
                console.log(response.data);
                SetLoadingCalendars(false)
            })
            .catch(e => {
                console.log(e);
                SetLoadingCalendars(false)
            });
    }

    const setActiveCalendar = (calendar, index) => {
        SetCurrentCurrentCalendar(calendar)
        SetCurrentIndex(index)
    }

    const commissioningCalendar = (calendarId) => {
        SetDisableCommissioning(true)
        SetFormError(false)
        SetFormSuccessfull(false)
        CommissioningService.update(calendarId)
            .then(response => {
                SetDisableCommissioning(false)
                SetFormSuccessfull(true)
                console.log(response.data);
            })
            .catch(e => {
                SetDisableCommissioning(false)
                SetFormError(true)
                console.log(e);
            });
    }


    useEffect(() => {
        CalendarService.getAll()
            .then(response => {
                SetCalendars(response.data)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, [])

    return (
        <div class="container">
            <div class="row my-3">
                <h4>Commissioning calendar</h4>
            </div>
            <div class="row">
                <div class="col-md-4 mb-3">
                    <h5>List calendars</h5>
                    <ul class="list-group">
                        {calendars &&
                            calendars.map((calendar, index) => (
                                <li
                                    class={
                                        "list-group-item list-group-item-light " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => setActiveCalendar(calendar, index)}
                                    key={calendar.id}
                                >
                                    {calendar.name}
                                </li>
                            ))}
                    </ul>
                </div>
                <div class="col-md">
                    {currentCalendar ? (
                        <div>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                {currentCalendar.name}
                            </div>
                            <div class="mb-3">
                                <button
                                    type="button"
                                    disabled={disableCommissioning}
                                    class="btn btn-primary"
                                    onClick={() => commissioningCalendar(currentCalendar.calendarId)}
                                >
                                    Commissioning
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <p>Please select a calendar...</p>
                        </div>
                    )}
                </div>
                {
                    formError && formError === true ?
                        (<div class="alert alert-danger" role="alert">
                            Cannot commission calendar!
                        </div>) : (null)
                }
                {
                    formSuccessfull && formSuccessfull === true ?
                        (<div class="alert alert-success" role="alert">
                            Calendar commissioned!
                        </div>) : (null)
                }

            </div>
        </div >
    )
}
export default CommissioningCalendar;