import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CalendarService from "../services/calendar.service";
import ControlProgramService from "../services/control-program.service";
import { Button } from "react-bootstrap";

const CalendarList = (props) => {
    const [currentCalendar, SetCurrentCurrentCalendar] = useState(null)
    const [calendars, SetCalendars] = useState([])
    const [currentIndex, SetCurrentIndex] = useState(-1)
    const [controlPrograms, SetControlPrograms] = useState(null)
    const [loadingControlPrograms, SetLoadingControlPrograms] = useState(false)
    const [loadingCalendars, SetLoadingCalendars] = useState(false)

    const getControlPrograms = () => {
        SetLoadingControlPrograms(true)
        ControlProgramService.getAll()
            .then(response => {
                SetControlPrograms(response.data)
                SetLoadingControlPrograms(false)
            })
            .catch(e => {
                console.log(e);
                SetLoadingControlPrograms(false)
            })
    }

    const getCalendars = () => {
        SetLoadingCalendars(true)
        CalendarService.getAll()
            .then(response => {
                SetCalendars(response.data)
                console.log(response.data);
                SetLoadingCalendars(false)
            })
            .catch(e => {
                console.log(e);
                SetLoadingCalendars(false)
            });
    }

    const setActiveControlProgram = (calendar, index) => {
        SetCurrentCurrentCalendar(calendar)
        SetCurrentIndex(index)
    }

    const deleteCalendar = (calendar) => {
        CalendarService.remove(calendar.id)
            .then(response => {
                SetCurrentCurrentCalendar(null)
                SetCalendars((current) =>
                    current.filter((item) => item.id !== calendar.id))
                SetCurrentIndex(-1)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }


    const DisplayTimeControlType = (index) => {
        if (index === 1)
            return "date"
        else
            return "day of the week"
    }

    const getControlProgramName = (controlProgramId) => {
        const selectControlProgram = controlPrograms.filter(item => item.controlProgramId == controlProgramId)
        if (selectControlProgram !== null && selectControlProgram.length > 0) {
            return selectControlProgram[0].name
        }
        else
            return null
    }

    useEffect(() => {
        getControlPrograms()
        CalendarService.getAll()
            .then(response => {
                SetCalendars(response.data)
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
    }, [])

    return (
        <div class="container">
            <div class="row my-3">
                <h4>Calendars</h4>
            </div>
            <div class="row">
                <div class="col-md-4 mb-3">
                    <h5>List calendars</h5>
                    <ul class="list-group">
                        {calendars &&
                            calendars.map((calendar, index) => (
                                <li
                                    class={
                                        "list-group-item list-group-item-light " +
                                        (index === currentIndex ? "active" : "")
                                    }
                                    onClick={() => setActiveControlProgram(calendar, index)}
                                    key={calendar.id}
                                >
                                    {calendar.name}
                                </li>
                            ))}
                    </ul>
                    <Link
                        to="/add-calendar"
                        type="button"
                        class="btn btn-primary my-3">
                        Add
                    </Link>
                </div>
                <div class="col-md">
                    {currentCalendar ? (
                        <div>
                            <div>
                                <label>
                                    <strong>Name:</strong>
                                </label>{" "}
                                {currentCalendar.name}
                            </div>
                            <div>
                                <label>
                                    <strong>Default control program:</strong>
                                </label>{" "}
                                {getControlProgramName(currentCalendar.defaultCtrProgId)}
                            </div>
                            <div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Control program</th>
                                            <th>Time control type</th>
                                            <th>Start</th>
                                            <th>End</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentCalendar.rules &&
                                            currentCalendar.rules.map((rule, index) => (
                                                <tr key={`${index}`}>
                                                    <td>{getControlProgramName(rule.ctrProgId)}</td>
                                                    <td>{DisplayTimeControlType(rule.timeConditiontype)}</td>
                                                    <td>{rule.start}</td>
                                                    <td>{rule.end}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div class="mb-3">
                                <Link
                                    to="/add-calendar"
                                    state={{ currentCalendar }}
                                    type="button" class="btn btn-primary mx-2"
                                >
                                    Edit
                                </Link>
                                <Button
                                    type="button"
                                    class="btn btn-primary mx-2"
                                    onClick={() => deleteCalendar(currentCalendar)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <br />
                            <p>Please select a calendar...</p>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}
export default CalendarList;